@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@100..900&display=swap');
* {
    font-family: "Noto Sans Arabic", sans-serif;
  }
  
.login-container {
    display: flex;
    height: 100vh;
    background-color: #f5f5f5;
  }
  form {
    width: 50%;
  }
  .login-image {
    flex: 1;
  }
  
  .login-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .login-form {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #ffffff;
    
    
  }
  
  .login-logo {
    margin-bottom: 20px;
   
  }
  
  .login-form h1 {
    margin-bottom: 10px;
    font-size: 40px;
    color: #000000;
    
  }
  
  .login-form p {
    margin-bottom: 85px;
    color: #848484;
    font-size: 16;
    font-weight: 540;
    margin-top: -8px;
    
  }
  
  .login-input {
    direction: rtl;
    width: 100%;
    max-width: 500px;
    height: 50px;
    padding: 10px;
    margin-bottom: 30px;
    border: 2px solid #e9e9e9;
    background-color: #e9e9e9;
    border-radius: 32px;
    font-size: 16px;
   
    

  }
  
  .login-button {
    
    width: 100%;
    max-width: 200px;
    padding: 10px;
    background-color: #062F67;
    color: #ffffff;
    border: none;
    border-radius: 32px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 150px;
    text-align: center;
    
  }
  
  .login-button:hover {
    background-color: #ffffff;
    border: 2px solid #062F67;
    color: #062F67;
    font-weight: bold;
  }
  .login-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
}






.error-message {
  color: red;
  font-size: 1rem;
  margin-top: 1rem;
 
 margin-left: 100px;
 font-weight: 540;

}




.password-field {
  position: relative;
  margin-top: 10px;
}

.password-field .login-input {
  width: 100%;
}

.password-field .eye-icon {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust as needed */
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1; 
}

.eye-icon {
  margin-right: 85%;
  margin-top: -10px;
}


