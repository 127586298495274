@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@100..900&display=swap');

* {
    font-family: "Noto Sans Arabic", sans-serif;
    box-sizing: border-box;
   
}

body {
    background-color: #ffffff;
    margin: 0;
    padding: 0;
  
}

.form-container {
    width: 100%;
    height: 100%;
    direction: rtl;
    padding: 60px;
    background-color: rgb(255, 255, 255);

}
.form {
    margin-right: 25%;
}
.logo-container {
   top: 0;
   
}

.formh1 {
    
    margin-right:40% ;
    margin-bottom: 45px;
    font-size: 35px;
    font-weight: 800;
    color: #000000;
}

.formh2 {
    margin-right:40% ;
    margin-bottom: 45px;
    font-size: 35px;
    font-weight: 800;
    color: #000000;
} 

.form-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    
    
}

.labelform {
    width: 140px;
    font-size: 16px;
    font-weight: 800;
    color: #000000;
}

.input-form {
    flex: 1;
    padding: 10px;
    font-size: 16px;
    border-radius: 32px;
    border: 2px solid #E9E9E9;
    background-color: #E9E9E9;
}
.Property {
    flex: 1;
    padding: 10px;
    font-size: 16px;
    border-radius: 32px;
    border: 2px solid #E9E9E9;
    background-color: #E9E9E9;

    -webkit-appearance: none; /* For Safari and Chrome */
    -moz-appearance: none;    /* For Firefox */
    appearance: none;         /* Standard syntax */

    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="1px" height="24px"%3E%3Cpath d="M7 10l5 5 5-5z"/%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: left 15px center; /* Adjust left and center position */
    background-size: 30px; /* Size of the arrow */
    padding-left: 40px; /* Space for the arrow */
    position: relative;
    cursor: pointer;
}





.upload-button, .submit-button , .success-button {
   
    padding: 10px;
    margin-top: 20px;
    background-color: #062F67;
    color: #FFFFFF;
    border: none;
    cursor: pointer;
    font-size: 16px;
    

}

.upload-button {
    background-color: #E9E9E9;
    color: #062F67;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 300px;
    margin-right: 40%;
    border-radius: 32px;
   


}


.upload-button.valid-border {
    border: 2px solid #062F67; 
}



.upload-button img {
    margin-left: 10px;
}

.submit-button {
    text-align: center;
    background-color: #062F67;
    margin-top: 60px;
    transition: background-color 0.3s;
   width:100%;
   max-width: 800px;
   align-items: center;
   margin-right: 15%;
   border-radius: 40px;
}

.submit-button:hover ,
.success-button:hover {
    background-color: #E9E9E9;
    border: 2px solid #062F67;
    color: #062F67;
    font-weight: bolder;
}



input[type="file"] {
    display: none; /* Hide the actual file input */
}

.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

/* User profile section */
.user-profile {
    display: flex;
    align-items: center;
    direction: ltr;
}

.profile-pic {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

.user-info {
    font-size: 15px;
    color: #000000;
    margin-left: 5px;

}
.user-info p {
    font-size:17px;
    font-weight: 800;
    margin: -5px;
}
.user-info span {
    font-weight: 500;
    margin-left: -5px;

    
    
}


.logo-container {
    max-width: 400px; /* Adjust the size as needed */
}

.logo {
    width: 100%;
    height: auto;
}

.success-message {
     width: 100%;
    height: 100%;
    display:flex;
    position: relative;
    flex-direction: column;
    align-items: center ;
    justify-content: center;
    min-height: 60vh;
    text-align: center;
    background-color: #ffffff;
    padding: 20px;
}

.success-icon {
    margin-bottom: 20px;
}

.success-icon img {
    width: 150px;
    height: 150px;
}

.success-message h1 {
    margin-left:38%;
    
    color: #000000;
    margin-bottom: 50px;
    width: 100%;
}

.success-button {
    text-align: center;
    background-color: #062F67;
    margin-top: 60px;
    transition: background-color 0.3s;
   height: 60px;
   width: 400px;
   align-items: center;
 margin-right: 1%;
   border-radius: 40px;
   
}




.error-text {
    color: red;
    font-size: 0.875rem;
    text-align: center;
    
}

.error1-message {
    color: #062F67;
    font-size: 1.1rem;
    margin-top: 1rem;
    align-items: center;
    justify-content: center;
    text-align: center;
   margin-right: 100px;
   font-weight: 500;

}


.error-message {
    color: red;
    font-size: 1rem;
    margin-top: 1rem;
    align-items: center;
    justify-content: center;
    text-align: center;
   margin-right: 12%;

}


.dropdown-out {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.15);
    z-index: 1000;
}

.dropdown-out {
    display: block;
    padding: 10px;
    text-align: left;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
}

.dropdown-out:hover {
    background-color: #f5f5f5;
}
  .archform {
    padding: 10px 20px;
    border: none;
    width: 150px;
    height: 50px;
  color:white;
  background-color: #062F67;
  border-radius: 32px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    margin-right:-50%;
  

    
    
  }



  .table-container {
    margin-top: 20px;
    overflow-x: auto;
  }
  
  /* Table styling */
  .data-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
  }
  
  .data-table th,
  .data-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    font-weight: 500;
  }
  
  .data-table th {
    background-color: #062F67;
    color: #f8f8f8;
  }
  
  .data-table tr {
    background-color: #ffffff;
  }
  
  .data-table tr:hover {
    background-color: #e0e0e0;
  }
  
  /* Error message styling */
  .error-message {
    color: red;
    font-size: 1.2em;
    margin-top: 20px;
  }
  
  /* Loading state styling */
  .loading {
    font-size: 1.2em;
    color: #666;
  }



  .pagination {
    margin-top: 20px;
    text-align: center;
  }
  
  .pagination button {
    background-color: #E9E9E9;
    border: none;
    color: rgb(0, 0, 0);
    padding: 5px 15px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 500;
  }
  
  .pagination button.active {
    background-color: #062F67;
    color: white;
  }
  
  .pagination button:hover {
    background-color: #ffffff;
    border: 2px solid #062F67;
    color: #000000;
  }


  .side {
   
   
    padding: 20px;
    display: flex;
    flex-direction: column; 
    gap: 10px;
    position: fixed; 
    margin-right: 40px;
  }