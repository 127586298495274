.card-container {
    width: 100%;
   
    margin: 0 auto;
    margin-top: 16px;
  }
  
  .card {
    padding: 16px;
    border-bottom: 2px solid #d5d5d5;
    
  }

  .linecard {
    border-bottom: 2px solid #d5d5d5;
    margin-top: 50px;

  }
  
  .card-datetime {
    font-size:16px;
    font-weight: 400;
    color: #000000;
   text-align:start;
   direction: ltr;
   

  }
  
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
   
    
   
  }
  
  .card-header-content {
    display: flex;
    align-items: center;
  }
  
  .role-indicator {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 8px;
  }
  
  .card-username {
    font-size:16px;
    font-weight: 600;
    
  }
  
  .card-userrole {
    font-size:16px;
    font-weight: 400;
    color: #000000;
  }
  
  .card-transfer {
    margin-top: 16px;
  }
  
  .transfer-label {
    font-size:16px;
    font-weight: 600;
    
  }
  
  .transfer-to {
    font-size:16px;
    font-weight: 400;
    color: #000000;
  }
  
  .card-message {
    background-color: #e9e9e9;
    border-radius: 32px;
    height: 90px;
    padding: 8px;
    margin-top: 16px;
  }
  