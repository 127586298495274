.chat-container{
  display: flex;
  flex-direction: column;
  height: 100vh;
  

}



.chat-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    margin-bottom: 20px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    max-height: 60vh;
  }
  
  .message {
    align-self: flex-start;
    background-color: #062F67;
    padding: 10px;
    border-radius: 10px;
    max-width: 60%;
    word-wrap: break-word;
    color: white;
    font-size: 16px;
    font-weight: 450;
  }
  
  /* Input area styling */
  .input-containerC {
    display: flex;
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
    
  }
  
  .inputC {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-left: 10px; 
    background-color: #f1f1f1;
    font-size: 16px;
    
  }
  
  .chatb {
    padding: 10px 20px;
    background-color: #062F67;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
 