
.user-cell {
    font-size: 16px;
    font-weight: 800;
    color: #ffffff;
    background-color: #062F67;
    text-align: right;
   
}

.user1-cell {
    font-size: 16px;
   font-weight: 540;
    color: #000000;
    text-align: right;
}



.clickable-row:hover {
background-color: #e9e9e9;

}





.add-form {
    background: white;
    padding: 20px;
    border-radius: 26px;
    width: 700px;
    height: 630px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}



.add-form h2 {
    margin-bottom: 25px;
    font-size: 20px;
    color: #000000;
    
}

.add-form label {
    display: flex;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 550;
}



.add-form span {
    display: inline-block;
    width: 100px;
    color: #000000;
}

.add-form input {
    width: calc(100% - 110px);
    padding: 8px;
    border: 1px solid #E9E9E9;
    border-radius: 5px;
    color: #000000;
    background-color: #E9E9E9;
    font-size: 15px;
    font-weight: 540;
}

.add-form select {
    width: calc(100% - 110px);
    padding: 8px;
    border: 1px solid #E9E9E9;
    border-radius: 5px;
    color: #000000;
    background-color: #E9E9E9;
    font-size: 15px;
    font-weight: 540;
}







.edit2-form {
    background: white;
    padding: 20px;
    border-radius: 26px;
    width: 700px;
    height: 660px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}



.edit2-form h2 {
    margin-bottom: 25px;
    font-size: 20px;
    color: #000000;
    
}

.edit2-form label {
    display: flex;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 550;
}



.edit2-form span {
    display: inline-block;
    width: 100px;
    color: #000000;
}

.edit2-form input {
    width: calc(100% - 110px);
    padding: 8px;
    border: 1px solid #E9E9E9;
    border-radius: 5px;
    color: #000000;
    background-color: #E9E9E9;
    font-size: 15px;
    font-weight: 540;
}

.edit2-form select {
    width: calc(100% - 110px);
    padding: 8px;
    border: 1px solid #E9E9E9;
    border-radius: 5px;
    color: #000000;
    background-color: #E9E9E9;
    font-size: 15px;
    font-weight: 540;
}





.dropdown-toggle2 {
    background-color: #e9e9e9;
    border-radius: 5px;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    width:550px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: -220px;
    font-size: 16px;
    
  }


  
.dropdown-menu2 {
    position: absolute;
    top: 115%;
    left: 0;
    background-color: #e9e9e9;
    border-radius: 5px;
    z-index: 1000;
    width: 550px;
    max-height:200px;
    overflow-y: auto;
    margin-left: -220px;
  
  }


  
.arrow-icon2 {
    margin-left: 1px; 
    transition: transform 0.3s ease;
    width:50px;
    height: 35px;
  }
  
  .arrow-icon2.open2 {
    transform: rotate(180deg);
  }
  
 



  .user-info-container {
    
    border-radius: 8px; 
    padding: 20px; 
    
    max-width: 1000px; 
    margin: 20px auto; 
}

.user-info-item {
    font-size: 16px; 
    line-height: 1.6; 
    margin: 30px 0; 
    display: flex; 
    align-items: center; 
}

.user-info-label {
    color: #000000; 
    display: inline-block; 
    width: 150px; 
    font-weight: bold; 
}

.user-info-value {
    color: #000000; 
    font-weight: 500;

    
    
}



.password-button {
    background-color: #062F67;
    color: white;
    border: none;
    width: 200px;
    height: 50px;
    border-radius: 40px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 650;
    margin-top: 20px;
}







.password-form {
    background: white;
    padding: 20px;
    border-radius: 26px;
    width: 600px;
    height: 470px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}



.password-form h2 {
    margin-bottom: 25px;
    font-size: 20px;
    color: #000000;
    
}

.password-form label {
    display: flex;
    margin-bottom: 15px;
    margin-top: 5px;
    font-size: 18px;
    font-weight: 550;
    
}



.password-form span {
    display: inline-block;
    width: 100px;
    color: #000000;
}

.password-form input {
    width: 100%;
    padding: 8px;
    border: 1px solid #E9E9E9;
    border-radius: 5px;
    color: #000000;
    background-color: #E9E9E9;
    font-size: 15px;
    font-weight: 540;
}

.password-form select {
    width: calc(100% - 110px);
    padding: 8px;
    border: 1px solid #E9E9E9;
    border-radius: 5px;
    color: #000000;
    background-color: #E9E9E9;
    font-size: 15px;
    font-weight: 540;
}

.password-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}


.form-error {
    display: flex;
    color: red;
    margin-top: 10px;


}
