
.multi-select-dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-toggle {
    background-color: #e9e9e9;
    border-radius: 32px;
    padding: 8px 16px;
    cursor: pointer;
    width: 780px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;

    
    
}

.dropdown-menu {
    position: absolute;
    top: 110%;
    left: 0;
    background-color: #e9e9e9;
    border-radius: 32px;
    z-index: 1000;
    width: 100%;
    max-height:400px;
    overflow-y: auto;
}

.dropdown-item {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
}




.arrow-icon {
    
    transition: transform 0.3s ease;
    width:50px;
    height: 35px;
    
  }
  .arrow-icon.open {
    transform: rotate(180deg);
}
  
input[type="checkbox"] {
    width: 15px;
    height: 15px;
    border: 2px solid black;
    border-radius: 3px;
    -webkit-appearance: none; 
    -moz-appearance: none;
    appearance: none; 
    background-color: white; 
    cursor: pointer;
    vertical-align:sub; 
    margin-left: 10px;
    
    
}

input[type="checkbox"]:checked {
    background-color: black; 
    
}

.selected-items-p {
font-size: 15px;
font-weight: 500;
color: #000;

}

