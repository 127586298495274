@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@100..900&display=swap');

* {
    font-family: "Noto Sans Arabic", sans-serif;
    box-sizing: border-box;
    
    
}



.internalM-container {
  width: 100vw;
  height: 150vh;
  direction: rtl;
  padding: 40px;
  background-color: #F9F9F9;
  margin: 0;
}
.memo-form {
    padding: 20px 20px;
    max-width: 520px;
    margin-right: 50px; 
    text-align: right; 
    background-color: #F9F9F9;
    margin-top: -830px;

  }
  
  .formM-group {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column; 
    align-items: flex-end;  
  }
  
  .formM-group label {
    margin-bottom: 8px;
    text-align: right;
    width: 100%;

  }
  
  .formM-group input[type="text"] {
    width: 600px;
    height: 45px;
    padding: 10px;
    border:none;
    border-radius: 32px;
    text-align: right;
    margin-left: auto;
    background-color: #e9e9e9;
    color: black;
    font-size: 16px;
    font-weight: 400;
  }
  .formM-group textarea{
    width: 600px;
    height: 185px;
    padding: 10px;
    border:none;
    border-radius: 32px;
    text-align: right;
    resize: none;
    margin-left: auto;
    background-color: #e9e9e9;
    color: black;
    font-size: 16px;
    font-weight: 400;
  }
  
  .Memo-buttons {
    display: flex;
    
    margin-top: 20px;
    direction: rtl; 
  }
  
  .send-button {
    background-color: #062F67;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 40px;
    cursor: pointer;
    width: 185px;
    height: 60px;
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
  }
  
  .print-button {
    background-color: #7D8692;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 40px;
    cursor: pointer;
    width: 185px;
    height: 60px;
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;

  }

  .view-button {
    background-color: #062F67;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 40px;
    cursor: pointer;
    width: 185px;
    height: 60px;
    font-size: 16px;
    font-weight: 600;
    
  }
  

  .document-content {

    width: 550px;
    padding: 20px;
    background-color: #ffffff; /* Change the color as needed */
   margin-right: 60%;
    border: none;
    border-radius: 13px;
    height: 820px; /* Make sure it grows with content */
}


.Memo {
  
  width: 477;
 margin-right: 15px;
}

h3 {
  background-color: white;
}

.labelM {
  width: 140px;
  font-size: 16px;
  font-weight: 800;
  color: #000000;
}

.print-icon {
  
    width: 18px;
    height: 18px;
    margin-left: 6px;
    margin-bottom: -4px;
}




.multi-select-dropdown1 {
  position: relative;
  display: inline-block;
}

.dropdown-toggle1 {
  background-color: #e9e9e9;
  border-radius: 32px;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  width: 600px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-left: -120px;
  font-size: 16px;
  text-align: right;
  justify-content: space-between;
  
}

.arrow-icon1 {
  margin-left: 8px; 
  transition: transform 0.3s ease;
  width:50px;
  height: 35px;
}

.arrow-icon.open1 {
  transform: rotate(180deg);
}

.dropdown-menu1 {
  position: absolute;
  top: 115%;
  left: 0;
  background-color: #e9e9e9;
  border-radius: 32px;
  z-index: 1000;
  width: 600px;
  max-height:400px;
  overflow-y: auto;
  margin-left: -120px;

}

.dropdown-item1 {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
    font-size: 18px;
    font-weight: 550;
}

input[type="checkbox"] {
  width: 15px;
  height: 15px;
  border: 2px solid black;
  border-radius: 3px;
  -webkit-appearance: none; 
  -moz-appearance: none;
  appearance: none; 
  background-color: white; 
  cursor: pointer;
  vertical-align:sub; 
  margin-left: 10px;
  
  
  
}

input[type="checkbox"]:checked {
  background-color: black; 
  
}


.text-overlay {
  position: relative;
  color: #000; 
  direction: rtl;
  text-align: right;
  white-space: pre-wrap;

  width: 90%; /* Limit width to control when text wraps */
  margin: 0 auto; /* Center it within its container */
 
}

.topicover {
  position: absolute;
  bottom: 540px;
  right:180px;
  width: 300px; /* Set a fixed width */
  height: 50px;  /* Set a fixed height */
  overflow: hidden; /* Hide overflow text */
  white-space: nowrap; /* Prevent the text from wrapping */
  text-overflow: ellipsis; /* Add ellipsis (...) if text is too long */
}



.topicover2 {
  position: absolute;
  bottom: 480px;

  right:180px;
  width: 300px; /* Set a fixed width */
  height: 50px;  /* Set a fixed height */
  overflow: hidden; /* Hide overflow text */
  white-space: nowrap; /* Prevent the text from wrapping */
  text-overflow: ellipsis; /* Add ellipsis (...) if text is too long */
}


.numover {
  position: absolute;
 bottom: 628px;
 left: 60px;

}

.dateover{
  position: absolute;
  bottom: 612px;
  left: 15px;
}



.noteover {
  position: absolute;
  bottom: 60px;
  left: 0px;
  white-space: pre-wrap; /* Change this to pre-wrap to handle wrapping properly */
  direction: rtl;
  text-align: right;
  height: 500px; 
  overflow: auto; /* Ensure that if text goes beyond this, it will not push content up */
  width: 100%;
  resize: none;
  border: none;
}



.noteover2 {
  position: absolute;
  bottom: 130px;
  left: 0px;
  white-space: pre-wrap; /* Change this to pre-wrap to handle wrapping properly */
  direction: rtl;
  text-align: right;
  height: 380px; 
  overflow: auto; /* Ensure that if text goes beyond this, it will not push content up */
  width: 100%;
  resize: none;
  border: none;
}



.sendfile-button {
  background-color: #E9E9E9;
  color: #062F67;
  padding: 10px 20px;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  height: 60px;
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
  white-space: nowrap; 
}


.sendfile-button img {
  margin-left: 10px; 
  vertical-align: middle;
}


