@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@100..900&display=swap');

* {
    font-family: "Noto Sans Arabic", sans-serif;
    box-sizing: border-box;
}


.dashboard-container {
    width: 100%;
    height: 100%;
    direction: rtl;
    padding: 50px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f9f9f9;
}

.main-content {
    display: flex;
    flex-grow: 1;
    width: 100%;
}

.side-menu {
    width:400px;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    margin-top: 2%;
    
}



.content-wrapper {
    flex-grow: 1;
    background-color: rgb(255, 255, 255);
    border-radius: 15px 15px 0 0;
    padding: 20px;
    margin-right: 20px; /* Adjust this value to control spacing between side-menu and content */
}


.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.header h2 {
    font-size: 1.5rem;
    margin: 0;
}

.memo-select {
    background-color: #062F67;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 40px;
    cursor: pointer;
    font-size: 1rem;
    width: 185px;
    height: 58px;
    -webkit-appearance: none; /* For Safari and Chrome */
    -moz-appearance: none;    /* For Firefox */
    appearance: none;         /* Standard syntax */

    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24px" height="24px"%3E%3Cpath d="M7 10l5 5 5-5z"/%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: left 25px center; /* Adjust left and center position */
    background-size: 35px; /* Size of the arrow */
    padding-left: 40px; /* Space for the arrow */
    position: relative;
    cursor: pointer;
}

.memo-select option {
    color: #000000; 
    background-color: #fff;
     
}

.memo-select:focus {
    outline: none;
    border-color: #9EB2E4; /* Border color on focus */
}



.table {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    
}



.menu-list {
    list-style: none; /* Remove bullet points */
    padding: 0;
    margin: 0;
}

.menu-link {
    display: flex;
    align-items: center;
     
    padding: 10px 15px;
    font-size: 1rem;
    text-decoration: none;
    color: inherit; /* Inherit text color */
    transition: background-color 0.3s ease;
    border-radius: 40px;
    margin-bottom: 15px;
    font-weight: 700;
}

.menu-link.active , .menu-link:hover {
    background-color: #9EB2E4; /* Background color for active or hover state */
    color: #000000;
}




.menu-count {
    margin-right: auto;
    margin-left: 10%; /* Pushes the count to the end */
    font-weight: 700;
}

.menu-icon {
    margin-left: 10px; /* Space between the icon and the text */
    width: 20px; /* Adjust icon size as needed */
    height: 20px; /* Adjust icon size as needed */
}



.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

/* User profile section */
.user-profile {
    display: flex;
    align-items: center;
    direction: ltr;
}

.profile-pic {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

.user-info {
    font-size: 15px;
    color: #000000;
    margin-left: 5px;

}
.user-info p {
    font-size:17px;
    font-weight: 800;
    margin: -5px;
}
.user-info span {
    font-weight: 500;
    margin-left: -5px;

    
    
}


.logo-container {
    max-width: 400px; /* Adjust the size as needed */
}

.logo {
    width: 100%;
    height: auto;
}



.signout {
    position:absolute;
    display: inline-block;
    bottom: 20px;
    width: 400px;
    list-style: none; 
   

  }