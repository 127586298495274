.table-section1 {
    margin-top: 20px;
  }
  
  .table-title {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  
  .notification-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .notification-table th, .notification-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center; 
    
  }
  
  .notification-table th {
    background-color: #062F67;
    font-weight: bold;
    color: white;
  }

  .notification-table td {

    cursor: pointer;
    font-weight: 450;
  }
  


  
table.notification-table th:nth-child(1) {
   width: 20%;
  }
  
 
  table.notification-table th:nth-child(2) {
   width: 20%;
  }


  table.notification-table th:nth-child(3) {
width: 30%;
  }


  table.notification-table th:nth-child(4) {
    width: 30%;
      }



      
    
      
      .selected-item-details h2 {
        
        color: #000000;
        margin-bottom: 20px;
        border-bottom: 2px solid #000000;
        padding-bottom: 10px;
      }
      
      .details-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
      }
      
      .grid-item {
        display: flex;
        flex-direction: column;
      }
      
      .grid-item label {
        font-weight: bold;
        margin-bottom: 5px;
      }
      
      .grid-item input, .grid-item textarea {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 10px;
        background-color: #f9f9f9;
        font-size: 16px;
        font-weight: 450;
        color: #000000;
        width: 100%;
      }
      
      .grid-item textarea {
        height: 150px;
        resize: vertical;
        resize: none;
      }
      


      
.noti-form {
  background: white;
  padding: 20px;
  border-radius: 26px;
  width: 700px;
  height: 550px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}



.noti-form h2 {
  margin-bottom: 25px;
  font-size: 20px;
  color: #000000;
  
}

.noti-form label {
  display: flex;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 550;
}



.noti-form span {
  display: inline-block;
  width: 100px;
  color: #000000;
}

.noti-form input , .notidetal {
  width: calc(100% - 110px);
  padding: 8px;
  border: 1px solid #E9E9E9;
  border-radius: 5px;
  color: #000000;
  background-color: #E9E9E9;
  font-size: 15px;
  font-weight: 540;
  resize: none;
}

.noti-form select {
  width: calc(100% - 110px);
  padding: 8px;
  border: 1px solid #E9E9E9;
  border-radius: 5px;
  color: #000000;
  background-color: #E9E9E9;
  font-size: 15px;
  font-weight: 540;
}

   





.multi-select-dropdown {
  position: relative;
  display: inline-block;
}

.dropform-toggle {
  background-color: #e9e9e9;
  border: 1px solid #E9E9E9;
  border-radius: 5px;
  padding: 8px;
  cursor: pointer;
  width: 550px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;

  
  
}

.dropform-menu {
  position: absolute;
  top: 110%;
  left: 0;
  background-color: #e9e9e9;
  border: 1px solid #E9E9E9;
  border-radius: 5px;
  z-index: 1000;
  width: 100%;
  max-height:400px;
  overflow-y: auto;
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
}




.arrow-icon {
  
  transition: transform 0.3s ease;
  width:50px;
  height: 35px;
  
}
.arrow-icon.open {
  transform: rotate(180deg);
}

input[type="checkbox"] {
  width: 15px;
  height: 15px;
  border: 2px solid black;
  border-radius: 3px;
  -webkit-appearance: none; 
  -moz-appearance: none;
  appearance: none; 
  background-color: white; 
  cursor: pointer;
  vertical-align:sub; 
  margin-left: 10px;
  
  
}

input[type="checkbox"]:checked {
  background-color: black; 
  
}

.selected-items-p {
font-size: 15px;
font-weight: 500;
color: #000;

}










  