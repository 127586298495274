@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@100..900&display=swap');
* {
    font-family: "Noto Sans Arabic", sans-serif;
  }
  
  .income-container {
    width: 100%;
    height: 100%;
    padding: 10px;

}
.header {
    display: inline-flex;
    align-items: center;
    margin-bottom: 10px;
    /* position: absolute;     */
}



.header-icon {
    width: 40px; 
    height: 40px;
    margin-right: 10px; 
}

.header-text {
    font-size: 40px; 
    font-weight: 600;
   margin-right: 20px;
 
    
}


.search-bar {
    display: flex;
    align-items: center;
    background-color: #e9e9e9;
    border-radius: 32px;
    padding: 5px 10px;
    width: 100%;
    max-width: 600px;
    height: 45px;
    justify-content: space-between;
    
  

}

.search-input {
    
    flex-grow: 1;
    border: none;
    outline: none;
    background-color: transparent;
    padding: 10px;
    font-size: 16px;
    color: #333;
    direction: rtl; /* To make the text direction right-to-left */
}

.search-button, .filter-button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 5px;
    
    
}

.search-button svg, .filter-button svg {
    fill: #848484; /* Color of the icons */
    vertical-align: middle;
}

.income-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 60px;
    
}

.income-table td {
    padding: 15px;
    border-bottom: 2px solid #EFEFEF;
    cursor: pointer;
}

.income-table th {
    padding: 15px;
    border-bottom: 2px solid #EFEFEF;
   
}

.status-cell {
    font-size: 16px;
    font-weight: 450;
}

.date-cell {
    
    font-size: 16px;
    font-weight: 450;
    color: #000000;
    text-align: center;
}

.section-cell {
    width: 20%;
    text-align: center;
    font-size: 16px;
    font-weight: 450;
}

.section-badge {
    width:auto;
    height: 40px;
    background-color: #C7D1E0;
    border-radius: 32px;
    font-size: 16px;
    color: #000000;
    overflow-y: hidden;
}

.subject-cell {
   font-size: 16px;
   font-weight: 450;
    color: #000000;
    text-align: right;
}
.sender-cell {
    font-size: 16px;
    font-weight: 800;
    color: #000000;
    text-align: right;
}

.status-icon {
    width: 24px;
    height: 24px;
    margin-left:15px; 
    vertical-align: middle;
}


/*  */

.detail-container {
    padding: 20px;
}



.detail-content {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-right: 50px;
}

.detail-info {
    margin-bottom: 20px;
    
}





.statuswithflag {
    font-size: 16px;
    font-weight: 450;
    vertical-align:super;
}

.detail-title {
    font-size: 16px;
    font-weight: 650;
    margin-right: 40px;
    
    

}

.detail-answer  {
    font-size: 16px;
    font-weight: 450px;
    
}

.status-icon-large {
    width: 24px;
    height: 24px;
    margin-top: 50px;
    margin-left: 20px;
}
.header-content {
    display: flex;
    align-items: center;
}
.header2 {
    display: inline-flex;
   justify-content: space-between;
    align-items: center;           
    width: 100%;   
}

.header-text2 {
    font-size: 40px; 
    font-weight: 600;
    margin-right: 20px;
   
}

.actions1 {
    display: flex;          /* Align buttons side by side */
    gap: 10px;              /* Add space between the buttons */
}

.actions2 {
    display: flex;
   justify-content: space-between;
    gap: 10%;
}

.edit-button, .delete-button {
    width: 135px;
    height: 45px;
    font-weight: 450;
    font-size: 16px;
    border: none;
    border-radius: 40px;
    cursor: pointer;
}

.editnum-button {
    width: 160px;
    height: 45px;
    font-weight: 450;
    font-size: 16px;
    border: none;
    border-radius: 40px;
    cursor: pointer; 
}

.forward-button {
    
    border: none;
    width: 231px;
    height: 45px;
    border-radius: 40px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 650;
    
  

}

.edit-button {
    
    background-color: #e9e9e9;
}

.delete-button {
    background-color: #e9e9e9;
}

.forward-button {
    background-color: #062F67;
    color: white;
}

.back-button {
    
    background-color: #062F67;
    color: white;
    border: none;
    width: 231px;
    height: 45px;
    border-radius: 40px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 650;

    
}

.button-icon {
    width: 18px; 
    height: 18px; 
    margin-left: 10px; 
}

.forward-icon {
    width: 22px;
    height: 22px;
    margin-left: 10px;
    margin-bottom: -6px;
}

.line {
    border: 1.5px solid #D9D9D9;
    width: 65%;
    
    margin-bottom: 80px;
}

.pdf {
    width: 315px;
    height: 435px; 
    background-color: #D9D9D9;
    margin-top: -300px;
    margin-right: 70%;
}



.edit-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}


.edit-form {
    background: white;
    padding: 20px;
    border-radius: 26px;
    width: 700px;
    height: 500px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.forward-form {
    background: white;
    padding: 20px;
    border-radius: 26px;
    width: 850px;
    height: 750px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    
    
}



.edit-form h2 {
    margin-bottom: 25px;
    font-size: 20px;
    color: #000000;
    
}

.edit-form label {
    display: flex;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 550;
}


.numdate-form {
    background: white;
    padding: 20px;
    border-radius: 26px;
    width: 700px;
    height: 500px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.numdate-form {
    background: white;
    padding: 20px;
    border-radius: 26px;
    width: 550px;
    height: 320px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    
    
}



.numdate-form h2 {
    margin-bottom: 25px;
    font-size: 20px;
    color: #000000;
    
}

.numdate-form label {
    display: flex;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 550;
}


.numdate-form span {
    display: inline-block;
    width: 100px;
    color: #000000;
}

.numdate-form input {
    width: calc(100% - 110px);
    padding: 8px;
    border: 1px solid #E9E9E9;
    border-radius: 5px;
    color: #000000;
    background-color: #E9E9E9;
    font-size: 15px;
    font-weight: 540;
}



.forward-form label {
    display: block;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 550;
    
}


.forward-form span {
    display: inline-block;
    width: 100px;
    color: #000000;
    font-size: 18px;
    font-weight: 600;
    
}

.edit-form span {
    display: inline-block;
    width: 100px;
    color: #000000;
}

.edit-form input {
    width: calc(100% - 110px);
    padding: 8px;
    border: 1px solid #E9E9E9;
    border-radius: 5px;
    color: #000000;
    background-color: #E9E9E9;
    font-size: 15px;
    font-weight: 540;
}

.edit-form select {
    width: calc(100% - 110px);
    padding: 8px;
    border: 1px solid #E9E9E9;
    border-radius: 5px;
    color: #000000;
    background-color: #E9E9E9;
    font-size: 15px;
    font-weight: 540;
}


.forward-form textarea {
    width: 780px;
    height: 300px;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 20px;
    border-radius: 32px;
    background-color: #e9e9e9;
    border: none;
    resize: none;
    font-size: 16px;
    font-weight: 400;

}

.forwardto {
    width:100%;
    padding: 8px;
    border: 1px solid #E9E9E9;
    border-radius: 20px;
    color: #000000;
    background-color: #E9E9E9;
    font-size: 15px;
    font-weight: 540;
    
    
}

.boxf {
    padding: 15px;
    
    
}


.edit-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.edit-actions2 {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.save-button, .cancel-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
}

.save-button {
    background-color: #062F67;
    
    color: white;
}

.done-button {
    border: none;
    border-radius: 40px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    background-color: #062F67;
    width: 330px;
    height: 52px;
    color: white;
}


.done-button:hover {
    background-color: #e9e9e9;
    color: #062F67;
    font-size: 16px;
    font-weight: 600;
    border: 2px solid #062F67;


}

.cancel-button {
    background-color: #676767;
    color: #ffffff;
}
.delete-icon {
   width: 17px;
   height: 17px; 
   margin-bottom: 20px;
   cursor: pointer;
   
}

.suggestion-bubbles {
    display: flex;
    gap: 20px;
    margin-bottom: 5px;
    text-align: center;
    justify-content: center;
    
}

.suggestion-bubbles span {
    display: inline-block;
    background-color: #E9E9E9;
    color: #000000;
    font-weight: 600;
    padding: 5px;
    width: 20%;
    border-radius: 20px;
    cursor: pointer;
    font-size: 15px;
    white-space: nowrap;  
    text-align: center;
}

.suggestion-bubbles span:hover {
    background-color: #ccc;
}


.pdfurl{
    justify-content: center;
    text-align: center;
}


.pdfurlB{
    color: white;
    background-color: #062F67;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    margin-right: 10px;
    width: 80px;
    border: none;

}

.pdfurlB.selected {
    background-color: #e9e9e9; /* Background color for selected */
    font-size: 16px;
    font-weight: 500;
 
 
    color: #062F67; /* Text color for selected */
}




@media (max-width: 768px) {
    .income-table td {
        font-size: 12px;
        padding: 8px;
    }

    .header-text {
        font-size: 20px;
    }

    .section-badge {
        font-size: 12px;
    }

    .search-input {
        font-size: 14px;
    }
}

@media (max-width: 768px) {
    .back-button, .forward-button {
        flex-grow: 0.5; /* Buttons take up less space on smaller screens */
        margin: 0 0.5%;
    }
}

@media (min-width: 769px) {
    .back-button, .forward-button {
        flex-grow: 1.5; /* Buttons take up more space on larger screens */
        margin: 0 2%;
    }
}






